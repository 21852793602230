<template>
  <div>
    <bfc-bar></bfc-bar>
    <lab-menu></lab-menu>
    <div style="height: 20px"></div>
    <v-app-bar color="gray" style="height: 48px">
      <v-breadcrumbs :items="breadcrumbs" style="margin-top: -16px">
        <template v-slot:item="{ item }">
          <v-breadcrumbs-item :href="item.href" :disabled="item.disabled">
            {{ item.text.toUpperCase() }}
          </v-breadcrumbs-item>
        </template>
      </v-breadcrumbs>
      <v-spacer></v-spacer>
    </v-app-bar>
    <div style="height: 20px"></div>

    <v-card>
      <v-card-text>
        <template>
          <v-card>
            <v-card-title>Select Package</v-card-title>
            <v-card-title>
              <v-container fluid>
                <v-row>
                  <v-select
                    v-model="choosePackage"
                    :items="casePackage"
                    item-text="text"
                    item-value="value"
                    label="Please Select Package"
                  ></v-select>
                </v-row>
              </v-container>
            </v-card-title>
          </v-card>
        </template>
        <v-divider></v-divider>
        <div style="margin-top: 20px">
          <v-btn color="primary" @click="savePackage"> Select Package </v-btn>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import SystemBar from "../../library/system-bar/system-bar";
import LabMenu from "../menubar/menubar";
//import { mapActions, mapGetters } from "vuex";
//import { nullOrUndefined } from "../../../../plugins/libs.js";
import axios from "axios";
//import { bfcPackage, r3Package } from "../../config/config.js";
export default {
  components: {
    "bfc-bar": SystemBar,
    "lab-menu": LabMenu,
  },
  data() {
    return {
      caseNo: "",
      case: {},
      caseStatus: {},
      bfcPackage: [],
      r3Package: [],
      choosePackage: "",
      //casePackage: this.$route.params.sequence != 3 ? bfcPackage : r3Package,
      url: process.env.VUE_APP_LOYALTY_API_URI,
      breadcrumbs: [
        {
          text: "Dashboard",
          disabled: false,
          href: "/lab/dashboard",
        },
        {
          text: "Select Package",
          disabled: true,
          href: "/lab/",
        },
      ],
    };
  },
  computed: {
    casePackage() {
      return this.$route.params.sequence != 3 ? this.bfcPackage : this.r3Package;
    },
  },

  mounted() {
    //get case info
    console.log("mounted");
    console.log(this.casePackage);
    this.init();
    this.getPackage();
  },
  methods: {
    async getPackage() {
      await axios.get(this.url + "/api/bfc/v1/packages/all").then(async (response) => {
        // console.log(response);
        console.log(response.data);
        if (response.data.status == 0) {
          this.bfcPackage = response.data.data.filter((item) => {
            return item.type == "bfcPackage";
          });
          this.r3Package = response.data.data.filter((item) => {
            return item.type == "r3Package";
          });
        } else {
          // show error
          console.log("error response");
        }
        console.log(this.bfcPackage);
      });
    },
    async init() {
      console.log("init");
      this.breadcrumbs.push({
        text: "Case : " + this.$route.params.caseNo,
        disabled: true,
        href: "#",
      });

      console.log(this.$route.params.caseNo);
      await axios
        .post(
          this.url + "/api/bfc/v1/case/caseInfo",
          { caseNo: this.$route.params.caseNo }, // need to change
          {
            headers: {
              // Overwrite Axios's automatically set Content-Type
              "Content-Type": "application/json",
            },
          }
        )
        .then(async (response) => {
          // console.log(response);
          console.log(response.data);
          if (response.data.statusCode == 0) {
            this.case = response.data.data[0];
            this.choosePackage = this.case.package;
            //this.caseStatus = this.case.caseStatus;
          } else {
            // show error
            console.log("error response");
          }
        });
    },

    async savePackage() {
      let selectPackage = this.casePackage.filter((item) => {
        return item.value == this.choosePackage;
      });

      selectPackage[0].packageStatus =
        typeof this.case.package !== "undefined"
          ? this.case.package.packageStatus
          : "Waiting";
      //console.log(selectPackage);
      try {
        let payload = { caseNo: this.$route.params.caseNo, package: selectPackage[0] };
        //console.log(payload);
        //console.log(selectPackage);
        await axios
          .post(
            this.url + "/api/bfc/v1/lab/updatePackage",
            // need to change
            payload,
            {
              headers: {
                // Overwrite Axios's automatically set Content-Type
                "Content-Type": "application/json",
              },
            }
          )
          .then(async (response) => {
            console.log(response);
            if (response.data.statusCode == 0) {
              alert("Saved!");
              this.$router.back();
            } else {
              // show error
              console.log("error response");
            }
          });
      } catch (e) {
        //
        console.log(e);
      }
    },
  },
};
</script>
